
import { defineComponent, ref, nextTick, onMounted, withKeys } from 'vue';
import { ElMessage } from 'element-plus';
import { useRouter, useRoute } from 'vue-router';
import SelectImgDialog from '@/components/select-img-dialog/index.vue';
import Goods from './components/goods.vue';
import DetailModal from './components/detail-modal/index.vue';
import { processRouterQueryParams } from '@/utils/';
import useGoodsList from './composables/use-goods-list';
import { useSearchConfig } from './composables/use-search-config';
import {
  confirmImage,
  getDesignTaskOptions,
} from '@/modules/task-manage/design-task/api/index';
import {
  IGetDesignTaskOptionsRes,
} from '@/modules/task-manage/design-task/api/types';

import {
  SORT_TYPE_ENUM,
} from '@/modules/resource-manage/style/constant';
import {
  IGoodsItem,
} from './type.d';
import usePermissionConfig from '../../use-permission-config';
import { CHANNEL_ENUM } from '@/constant/global';
import { trackingClick } from '@/utils/tracking';

export default defineComponent({
  components: {
    Goods,
    SelectImgDialog,
    DetailModal,
  },
  setup() {
    const $router = useRouter();
    const $route = useRoute();
    const permissionConfig = usePermissionConfig();
    const goodsEl = ref<null|HTMLElement>(null);
    // 列表查询
    const {
      sortFiltersTags,
      handleRemoveFilterItem,
      handleCurrentSizeChange,
      handleReset,
      params,
      goodsList,
      reload,
      listLoading,
      finish,
      listDisabled,
      loadMore,
      isEmpty,
      sortList,
      sortConfig,
      handleChangeSort,
      initPagination,
      currentViewPage,
      pageSize,
      total,
      keyword,
      handleConfirmKeyword,
    } = useGoodsList();
    const {
      searchConfig,
      advancedSearchConfig,
      getOptions,
    } = useSearchConfig(params);
    getOptions();
    const designTaskOptions = ref<IGetDesignTaskOptionsRes[]>([]);
    const getDesignOptions = async () => {
      try {
        const { data } = await getDesignTaskOptions();
        designTaskOptions.value = data;
      } catch (error) {
        console.log(error);
      }
    };
    getDesignOptions();
    const init = async () => {
      await nextTick();
      // url 获取请求参数
      const urlParams = processRouterQueryParams(params.value);
      initPagination(goodsEl.value!);
      params.value = urlParams;
    };
    const selectImgDialogConfig = ref({
      visible: false,
      urls: [] as string[],
      categoryClass1: '',
      skc: '',
    });
    const detailConfig = ref({
      visible: false,
      data: {
        channelId: '',
        skc: '',
      },
    });
    // 详情弹窗
    const initDetail = () => {
      const { skc, channelId } = $route.query;
      if (channelId && skc) {
        detailConfig.value.data = {
          channelId: channelId as string,
          skc: decodeURIComponent(skc as string),
        };
        detailConfig.value.visible = true;
      }
    };
    const handleSelectImgItem = async (
      url: string,
      category: string,
      type: 'quick' | 'normal',
      skc: string,
      isFromDetail?: boolean,
    ) => {
      const id = localStorage.getItem('quick_design_id');
      let eventDetails: { [key: string]: string; } = {
        eventName: isFromDetail ? '详情弹窗-选图' : '选图',
        goodId: skc || '',
      };
      if (type === 'quick') {
        eventDetails = {
          ...eventDetails,
          eventName: isFromDetail ? '详情弹窗-快速选图' : '快速选图',
          taskId: id || '',
        };
      }
      trackingClick(eventDetails);
      if (type === 'quick' && id) {
        await confirmImage({
          id,
          selectedImg: url,
          categoryClass1: category,
        });
        const row = designTaskOptions.value.find(v => v.id === id);
        if (row) {
          ElMessage.success(`已添加到想法${row.designTaskName}`);
        }
        return;
      }
      selectImgDialogConfig.value.skc = skc;
      selectImgDialogConfig.value.urls = [url];
      selectImgDialogConfig.value.visible = true;
      selectImgDialogConfig.value.categoryClass1 = category;
    };
    const handleImageItem = (row: IGoodsItem) => {
      detailConfig.value.data = {
        channelId: row.channelId,
        skc: row.skc,
      };
      detailConfig.value.visible = true;
    };
    // 点击图片
    const handleGoodItem = (row: IGoodsItem) => {
      trackingClick({
        eventName: '查看商品详情',
        goodId: row.skc,
      });
      handleImageItem(row);
    };
    // 找相似
    const handleSearchImage = (url: string, skc: string, name: string) => {
      trackingClick({
        eventName: name,
        goodId: skc,
      });
      const newPage = $router.resolve({
        name: 'SearchImageList',
        query: {
          url: encodeURIComponent(url),
          channelId: CHANNEL_ENUM.HAIWAI,
        },
      });
      window.open(newPage.href);
    };
    const handleImage = (row: IGoodsItem) => {
      handleSearchImage(row.imageUrl, row.skc, '找相似');
    };

    // 选图弹窗-确定
    const handleSelectSuccess = (id: string) => {
      trackingClick({
        eventName: '选图弹窗-点击确定',
        goodId: selectImgDialogConfig.value.skc,
        taskId: id,
      });
    };
    onMounted(() => {
      init();
      initDetail();
    });
    return {
      handleRemoveFilterItem,
      sortFiltersTags,
      permissionConfig,
      handleCurrentSizeChange,
      total,
      currentViewPage,
      pageSize,
      goodsEl,
      handleReset,
      advancedSearchConfig,
      handleChangeSort,
      sortList,
      sortConfig,
      searchConfig,
      designTaskOptions,
      handleSelectImgItem,
      selectImgDialogConfig,
      goodsList,
      listLoading,
      finish,
      listDisabled,
      loadMore,
      isEmpty,
      params,
      useSearchConfig,
      handleImageItem,
      detailConfig,
      keyword,
      handleGoodItem,
      handleImage,
      handleSelectSuccess,
      handleSearchImage,
      handleConfirmKeyword,
    };
  },
  render() {
    return (
      <div>
        <main class="container">
          <el-row class="container_search" justify="end">
            <el-col span={8}>
              <el-input
                v-model={this.keyword}
                placeholder="请输入商品名称进行搜索"
                input-style={{
                  paddingRight: '36px',
                  backgroundColor: '#EFF0F5',
                }}
                onKeydown={withKeys(this.handleConfirmKeyword, ['enter'])}
                v-slots={{
                  suffix: () => (
                    <i class="iconfont icon_icon_search container_search_icon" onClick={this.handleConfirmKeyword} />
                  ),
                }}
              />
            </el-col>
          </el-row>
          <filter-bar
            tags={this.sortFiltersTags}
            searchConfig={this.searchConfig}
            advancedSearchConfig={this.advancedSearchConfig}
            onReset={this.handleReset}
            onRemoveItem={this.handleRemoveFilterItem}
          />
          <sort-bar
            sortList={this.sortList}
            ascKey={SORT_TYPE_ENUM.TOP}
            descKey={SORT_TYPE_ENUM.DOWN}
            prop={this.sortConfig.sortWord}
            order={this.sortConfig.sortType}
            onHandleItem={this.handleChangeSort}
          />
          <section>
            <div
              class="goods-container"
              ref="goodsEl"
              v-infinite-scroll={this.loadMore}
              infinite-scroll-distance={50}
              infinite-scroll-disabled={this.listDisabled}
              infinite-scroll-immediate={false}
            >
              {this.goodsList.map((v, i) => (
                <goods
                  key={i}
                  data={v}
                  canSearchImage={this.permissionConfig.SEARCH_IMAGE.value}
                  onSelectImg={this.handleSelectImgItem}
                  onImage={this.handleGoodItem}
                  onSearch={this.handleImage}
                ></goods>
              ))}
            </div>
            {this.isEmpty ? (
              <empty />
            ) : (
              <div class="load-other">
                {this.listLoading && (
                  <p><i class="el-icon-loading"></i> 加载中...</p>
                )}
                {this.finish && (
                  <p>已经到底了</p>
                )}
              </div>
            )}
            <list-pagination
              total={this.total}
              current={this.currentViewPage}
              pageSize={this.pageSize}
              onCurrentChange={this.handleCurrentSizeChange}
            />
          </section>
        </main>
        <detail-modal
          v-model={[this.detailConfig.visible, 'visible']}
          data={this.detailConfig.data}
          canSearchImage={this.permissionConfig.SEARCH_IMAGE.value}
          onSelectImg={this.handleSelectImgItem}
          onGoods={this.handleImageItem}
          onSearch={this.handleSearchImage}
        />
        <select-img-dialog
          v-model={[this.selectImgDialogConfig.visible, 'visible']}
          urls={this.selectImgDialogConfig.urls}
          categoryClass1={this.selectImgDialogConfig.categoryClass1}
          options={this.designTaskOptions}
          onSuccess={this.handleSelectSuccess}
        />
      </div>
    );
  },
});
