import { computed, Ref, ref } from 'vue';

import { useDictionaryStore } from '@/store/dictionary';
import {
  CX_CUSTOM_DICTIONARY_KEY,
} from '@/constant/dictionary';
import {
  GOODS_STATE_LIST,
  GOODS_IS_NEW_LIST,
} from '@/modules/resource-manage/style/constant';
import {
  SEARCH_BASE_SETTING_ENUM,
  SEARCH_SALE_SETTING_ENUM,
  SEARCH_DESIGN_SETTING_ENUM,
} from '../constant';
import {
  ICustomParams,
  ISearchConfigItem,
} from '../type.d';

export const useSearchConfig = (
  params: Ref<ICustomParams>,
) => {
  const dictionaryStore = useDictionaryStore();

  const DESIGN_CATEGORY_OPTIONS = computed(() => dictionaryStore
    .cxDictionaryMap[CX_CUSTOM_DICTIONARY_KEY.CATEGORY] || []);
  const STYLE_OPTIONS = computed(() => dictionaryStore
    .cxDictionaryMap[CX_CUSTOM_DICTIONARY_KEY.STYLE] || []);
  const SKIRT_TYPE_OPTIONS = computed(() => dictionaryStore
    .cxDictionaryMap[CX_CUSTOM_DICTIONARY_KEY.SKIRT_TYPE] || []);
  const DESIGN_TAILORING_OPTIONS = computed(() => dictionaryStore
    .cxDictionaryMap[CX_CUSTOM_DICTIONARY_KEY.DESIGN_TAILORING] || []);
  const STORE_NAME_OPTIONS = computed(() => dictionaryStore
    .cxDictionaryMap[CX_CUSTOM_DICTIONARY_KEY.STORE_NAME] || []);
  const BRAND_NAME_OPTIONS = computed(() => dictionaryStore
    .cxDictionaryMap[CX_CUSTOM_DICTIONARY_KEY.BRAND_NAME] || []);

  const cascaderConfig = ref({
    multiple: true,
    value: 'label',
  });

  const searchConfig = computed(() => {
    const baseConfig: ISearchConfigItem[] = [
      {
        key: SEARCH_DESIGN_SETTING_ENUM.CATEGORY,
        render: () => {
          return (
            <custom-cascader
              label="品类"
              options={DESIGN_CATEGORY_OPTIONS.value}
              v-model={params.value.categoryClass}
              show-all-levels={false}
              props={cascaderConfig.value}
            />
          );
        },
      },
      {
        key: SEARCH_DESIGN_SETTING_ENUM.STYLE,
        render: () => {
          return (
            <virtual-select
              label="风格"
              v-model={params.value.style}
              multiple
              options={STYLE_OPTIONS.value}
              prop={{
                value: 'label',
              }}
            />
          );
        },
      },
      {
        key: SEARCH_DESIGN_SETTING_ENUM.SKIRT_TYPE,
        render: () => {
          return (
            <virtual-select
              label="裙型"
              v-model={params.value.skirtType}
              multiple
              options={SKIRT_TYPE_OPTIONS.value}
              prop={{
                value: 'label',
              }}
            />
          );
        },
      },
      {
        key: SEARCH_DESIGN_SETTING_ENUM.DESIGN_CUTTING,
        render: () => {
          return (
            <virtual-select
              label="设计裁剪"
              v-model={params.value.designTailoring}
              multiple
              options={DESIGN_TAILORING_OPTIONS.value}
              prop={{
                value: 'label',
              }}
            />
          );
        },
      },
      {
        key: SEARCH_BASE_SETTING_ENUM.BRAND_NAME,
        render: () => {
          return (
            <virtual-select
              label="品牌"
              v-model={params.value.brandName}
              multiple
              options={BRAND_NAME_OPTIONS.value}
              prop={{
                value: 'label',
              }}
            />
          );
        },
      },
      {
        key: SEARCH_BASE_SETTING_ENUM.COMMODITY_STATUS,
        render: () => {
          return (
            <custom-select
              label="商品状态"
              v-model={params.value.commodityStatus}
            >
              {GOODS_STATE_LIST.map(v => (
                <el-option value={v.value} label={v.label} />
              ))}
            </custom-select>
          );
        },
      },
      {
        key: SEARCH_BASE_SETTING_ENUM.IS_NEW,
        render: () => {
          return (
            <custom-select
              label="是否新品"
              v-model={params.value.isNew}
            >
              {GOODS_IS_NEW_LIST.map(v => (
                <el-option value={v.value} label={v.label} />
              ))}
            </custom-select>
          );
        },
      },
      {
        key: SEARCH_BASE_SETTING_ENUM.STORE_NAME,
        render: () => {
          return (
            <virtual-select
              label="店铺"
              v-model={params.value.storeName}
              multiple
              options={STORE_NAME_OPTIONS.value}
              prop={{
                value: 'label',
              }}
            />
          );
        },
      },
    ];
    return baseConfig;
  });
  const advancedSearchConfig = computed(() => {
    const baseConfig: ISearchConfigItem[] = [
      {
        key: SEARCH_SALE_SETTING_ENUM.SHELF_DATE,
        render: () => {
          return (
            <custom-date-picker
              label="上架日期"
              v-model={params.value.shelfDate}
            >
            </custom-date-picker>
          );
        },
      },
      {
        key: SEARCH_SALE_SETTING_ENUM.OFF_DATE,
        render: () => {
          return (
            <custom-date-picker
              label="下架日期"
              v-model={params.value.offDate}
            >
            </custom-date-picker>
          );
        },
      },
      {
        key: SEARCH_SALE_SETTING_ENUM.DAYS_ON_SHELF,
        render: () => {
          return (
            <>
              {params.value?.daysOnShelf?.length && (
                <custom-number-range
                  label="在架天数"
                  v-models={[
                    [params.value.daysOnShelf[0], 'rangeStart'],
                    [params.value.daysOnShelf[1], 'rangeEnd'],
                  ]}
                  unit="天"
                  min={0}
                  max={999999}
                  precision={0}
                  rule={[{
                    validator: (rules: any, value: any, cb: (err?: string) => void) => {
                      if (value?.length) {
                        const [s, e] = value;
                        if (s === '' && e === '') {
                          cb();
                        } else if (s === '' || e === '') {
                          cb('请填写完整的区间');
                        } else if (s > e) {
                          cb('最小值不能大于最大值');
                        } else {
                          cb();
                        }
                      } else {
                        cb();
                      }
                    },
                  }]}
                />
              )}
            </>
          );
        },
      },
      {
        key: SEARCH_SALE_SETTING_ENUM.COUPON_PRICE,
        render: () => {
          return (
            <>
              {params.value.couponPrice?.length && (
                <custom-number-range
                  label="价格"
                  v-models={[
                    [params.value.couponPrice[0], 'rangeStart'],
                    [params.value.couponPrice[1], 'rangeEnd'],
                  ]}
                  unit="元"
                  min={0}
                  max={999999}
                  precision={2}
                  rule={[{
                    validator: (rules: any, value: any, cb: (err?: string) => void) => {
                      if (value.length) {
                        const [s, e] = value;
                        if (s === '' && e === '') {
                          cb();
                        } else if (s === '' || e === '') {
                          cb('请填写完整的区间');
                        } else if (s > e) {
                          cb('最小值不能大于最大值');
                        } else {
                          cb();
                        }
                      } else {
                        cb();
                      }
                    },
                  }]}
                />
              )}
            </>
          );
        },
      },
    ];
    return baseConfig;
  });
  const getOptions = () => {
    dictionaryStore.getCxDictionary([
      CX_CUSTOM_DICTIONARY_KEY.BRAND_NAME,
      CX_CUSTOM_DICTIONARY_KEY.DESIGN_TAILORING,
      CX_CUSTOM_DICTIONARY_KEY.SKIRT_TYPE,
      CX_CUSTOM_DICTIONARY_KEY.STORE_NAME,
      CX_CUSTOM_DICTIONARY_KEY.STYLE,
      CX_CUSTOM_DICTIONARY_KEY.CATEGORY,
    ]);
  };
  return {
    searchConfig,
    advancedSearchConfig,
    getOptions,
  };
};
