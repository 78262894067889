// 设计属性
export enum SEARCH_DESIGN_SETTING_ENUM {
  /* 品类 */
  CATEGORY = 'categoryClass',
  /* 风格 */
  STYLE = 'style',
  /* 裙型 */
  SKIRT_TYPE = 'skirtType',
  /* 设计裁剪 */
  DESIGN_CUTTING = 'designTailoring',
}

export const SEARCH_DESIGN_SETTING_LIST = [
  { label: '品类', value: SEARCH_DESIGN_SETTING_ENUM.CATEGORY },
  { label: '风格', value: SEARCH_DESIGN_SETTING_ENUM.STYLE },
  { label: '裙型', value: SEARCH_DESIGN_SETTING_ENUM.SKIRT_TYPE },
  { label: '设计裁剪', value: SEARCH_DESIGN_SETTING_ENUM.DESIGN_CUTTING },
];

// 基本属性
export enum SEARCH_BASE_SETTING_ENUM{
  /* 渠道名称 */
  CHANNLE_NAME = 'channelName',
  /* 品牌 */
  BRAND_NAME = 'brandName',
  /* 店铺 */
  STORE_NAME = 'storeName',
  /* 商品状态 */
  COMMODITY_STATUS = 'commodityStatus',
  /* 是否新品 */
  IS_NEW = 'isNew',
}

export const SEARCH_BASE_SETTING_LIST = [
  { label: '渠道名称', value: SEARCH_BASE_SETTING_ENUM.CHANNLE_NAME },
  { label: '品牌', value: SEARCH_BASE_SETTING_ENUM.BRAND_NAME },
  { label: '店铺', value: SEARCH_BASE_SETTING_ENUM.STORE_NAME },
  { label: '商品状态', value: SEARCH_BASE_SETTING_ENUM.COMMODITY_STATUS },
  { label: '是否新品', value: SEARCH_BASE_SETTING_ENUM.IS_NEW },
];

// 销售属性
export enum SEARCH_SALE_SETTING_ENUM{
  /* 上架日期 */
  SHELF_DATE = 'shelfDate',
  /* 下架日期 */
  OFF_DATE = 'offDate',
  /* 在架天数 */
  DAYS_ON_SHELF = 'daysOnShelf',
  /* 价格（券后价） */
  COUPON_PRICE = 'couponPrice',
}

export const SEARCH_SALE_SETTING_LIST = [
  { label: '上架日期', value: SEARCH_SALE_SETTING_ENUM.SHELF_DATE },
  { label: '下架日期', value: SEARCH_SALE_SETTING_ENUM.OFF_DATE },
  { label: '在架天数', value: SEARCH_SALE_SETTING_ENUM.DAYS_ON_SHELF },
  { label: '价格', value: SEARCH_SALE_SETTING_ENUM.COUPON_PRICE },
];
