
import { defineComponent, PropType } from 'vue';
import { numberfuns } from 'cx-utils';

import { formatMillion } from '@/utils/helper';
import {
  INSPIRATION_IMG_TYPE_ENUM,
} from '@/modules/task-manage/design-task/constant';
import {
  GOODS_STATE_ENUM,
  GOODS_STATE_LIST,
} from '@/modules/resource-manage/style/constant';
import { IGoodsItem } from '../type.d';

export default defineComponent({
  emits: ['select-img', 'image', 'search'],
  props: {
    data: {
      type: Object as PropType<IGoodsItem>,
      default: () => ({}),
    },
    canSearchImage: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const handleSelectImgItem = (row: IGoodsItem, type: 'quick'|'normal' = 'normal') => {
      emit(
        'select-img',
        `0/-${row.skc}/-${row.channelId}/-${INSPIRATION_IMG_TYPE_ENUM.STYLE}`,
        row.categoryClass1,
        type,
        row.skc,
      );
    };
    const handleImageItem = (row: IGoodsItem) => {
      emit('image', row);
    };
    const handleSearch = (row: IGoodsItem) => {
      emit('search', row);
    };
    return {
      handleImageItem,
      GOODS_STATE_ENUM,
      GOODS_STATE_LIST,
      handleSelectImgItem,
      handleSearch,
      formatMillion,
    };
  },
  components: {},
});
