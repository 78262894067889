
import { defineComponent, ref, PropType, watch } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper';
import dayjs from 'dayjs';
import { datefuns } from 'cx-utils';
import { ElMessage } from 'element-plus';

import IconSpeed from '@/assets/images/home/icon_speed.png';
import IconLink from '@/assets/images/icon_link.png';
import IconStyle from '@/assets/images/icon_style_title.png';
import IconRightArrow from '@/assets/images/arrow_right.png';
import IconLeftArrow from '@/assets/images/arrow_left.png';
import IconGLSP from '@/assets/images/icon_glsp.png';

import {
  getStyleGoodsInfo,
  downloadImageBySkc,
} from '@/modules/resource-manage/style/api';
import {
  INSPIRATION_IMG_TYPE_ENUM,
} from '@/modules/task-manage/design-task/constant';
import {
  GOODS_IS_NEW_LIST,
  GOODS_STATE_LIST,
} from '@/modules/resource-manage/style/constant';
import { IGoodsItem } from '../../type.d';
import { trackingClick } from '@/utils/tracking';

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
  },
  emits: [
    'update:visible',
    'confirm',
    'select-img',
    'goods',
    'search',
  ],
  props: {
    data: {
      type: Object as PropType<{
        skc: string;
        channelId: string;
      }>,
      required: true,
    },
    visible: {
      type: Boolean,
      defalut: false,
    },
    canSearchImage: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const currentIndex = ref(0);
    const swiperEl = ref<any>(null);
    const info = ref<IGoodsItem|null>(null);
    const handleClose = async () => {
      emit('update:visible', false);
    };
    const getInfo = async () => {
      const {
        skc,
        channelId,
      } = props.data;
      currentIndex.value = 0;
      info.value = {} as IGoodsItem;
      const { data } = await getStyleGoodsInfo({
        channelId,
        skc,
      });
      if (data) {
        info.value = data;
      } else {
        handleClose();
        ElMessage.error('商品不存在');
      }
    };
    const handleOpen = () => {
      // console.log(props.info);
      // getInfo();
    };
    const handleItem = (index: number) => {
      if (index !== currentIndex.value) {
        currentIndex.value = index;
      }
    };
    const init = (el: any) => {
      swiperEl.value = el;
    };
    const handleNext = () => {
      if (!info.value) return;
      if (currentIndex.value < info.value.imageUrls.length - 1) {
        currentIndex.value += 1;
        swiperEl.value.slideTo(currentIndex.value);
      }
    };
    const handlePrev = () => {
      if (currentIndex.value > 0) {
        currentIndex.value -= 1;
        swiperEl.value.slideTo(currentIndex.value);
      }
    };
    const handleSelectImgItem = (row: IGoodsItem | null, type: 'quick'|'normal' = 'normal') => {
      if (!row) return;
      emit(
        'select-img',
        `0/-${row.skc}/-${row.channelId}/-${INSPIRATION_IMG_TYPE_ENUM.STYLE}`,
        row.categoryClass1,
        type,
        row.skc,
        true,
      );
    };
    const handleLink = (url: string) => {
      trackingClick({
        eventName: '详情弹窗-打开源链接',
        goodId: info.value?.skc,
      });
      window.open(url);
    };
    const handleBindGoodsItem = (row: IGoodsItem['infoEntityList'][0]) => {
      emit('goods', {
        channelId: row.channelId,
        skc: row.skc,
      });
    };

    const handleDownload = async () => {
      try {
        await downloadImageBySkc({
          skc: props.data.skc,
          channelId: props.data.channelId,
          filename: `${info.value?.skcName}-${datefuns.formatTime(new Date(), 'YYYY-MM-DD')}.zip`,
        });
      } catch (error) {
        console.log(error);
      }
      trackingClick({
        eventName: '详情弹窗-下载图片',
        goodId: info.value?.skc,
      });
    };

    watch([
      () => props.data,
      () => props.visible,
    ], ([, newVisible]) => {
      if (newVisible) {
        getInfo();
      }
    });

    const handleSearch = () => {
      emit('search', info.value?.imageUrls[currentIndex.value], info.value?.skc, '详情弹窗-找相似');
    };

    return {
      handleBindGoodsItem,
      info,
      handleSelectImgItem,
      handleLink,
      init,
      handleNext,
      handlePrev,
      handleItem,
      handleOpen,
      handleClose,
      modules: [Navigation],
      currentIndex,
      handleDownload,
      handleSearch,
    };
  },
  render() {
    return (
      <el-dialog
        modelValue={this.visible}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        center
        close-on-click-modal={false}
        close-on-press-escape={false}
        // show-close={false}
        width="1210px"
        custom-class="el-dialog-inner-scroll style_detail_dialog"
        v-slots={{
          title: () => (
            <div class="dialog_header">
              <div class="title">款式信息</div>
            </div>
          ),
        }}
      >
        {this.info?.skc && (
          <div class="container">
            <div class="container_header" ref="containerEl">
              <div class="left" onClick={() => this.handleLink(this.info?.skcLink || '')}>
                <div class="name">{this.info.skcName}</div>
                <img class="icon" src={IconLink} />
              </div>
              <div class="right">
                <div class="cate">
                  品类：
                  {
                    [
                      this.info.categoryClass1,
                      this.info.categoryClass2,
                      this.info.categoryClass3,
                    ].filter(v => v !== '').join('/')
                  }
                </div>
                <div class="btns">
                  <div class="select" onClick={() => this.handleSelectImgItem(this.info)}>选图</div>
                    <el-tooltip content="添加到最近一次选图分组" placement="bottom-start">
                      <div
                        class="quick"
                        onClick={() => this.handleSelectImgItem(this.info, 'quick')}
                      >
                        <img src={IconSpeed} />
                      </div>
                    </el-tooltip>
                </div>
                <div class="down" onClick={this.handleDownload}>下载图片</div>
              </div>
            </div>
            <div class="common_title">
              <div class="left">
                <img src={IconStyle} class="icon" />
                <div>商品概况</div>
              </div>
              <div class="right">更新时间：{dayjs(this.info.updateTime).format('YYYY年MM月DD日 HH:mm:ss')}</div>
            </div>
            <div class="main">
              <div class="cover_layout">
                <custom-image
                  hide-on-click-modal={true}
                  src={this.info.imageUrls[this.currentIndex]}
                  fit="cover"
                  class="main_cover"
                  preview-src-list={this.info.imageUrls}
                  initial-index={this.currentIndex}
                />
                {this.info.imageUrls.length > 0 && <div class="theme_layout">
                  <div class={{
                    btn: true,
                    prev: true,
                    disabled: this.currentIndex === 0,
                  }} onClick={this.handlePrev}>
                    <img src={IconLeftArrow} class="icon" />
                  </div>
                  <swiper
                    slides-per-view={4}
                    space-between={12}
                    slideToClickedSlide={true}
                    grabCursor={true}
                    centeredSlides={false}
                    onSwiper={this.init}
                  >
                    {this.info.imageUrls.map((v, i) => (
                      <swiper-slide
                        key={i}
                        class="item"
                        v-slots={{
                          default: () => (
                            <div
                              class={{
                                cover: true,
                                active: this.currentIndex === i,
                              }}
                              onClick={() => this.handleItem(i)}
                            >
                              <img
                                src={v}
                                alt=""
                                class="img"
                              />
                            </div>
                          ),
                        }}
                      />
                    ))}
                  </swiper>
                  <div class={{
                    btn: true,
                    next: true,
                    disabled: this.currentIndex > this.info.imageUrls.length - 1,
                  }} onClick={this.handleNext}>
                    <img src={IconRightArrow} class="icon" />
                  </div>
                </div>}
                {this.info.imageUrls[this.currentIndex]
                && this.canSearchImage && <el-tooltip content="找相似" placement="bottom-end">
                  <div onClick={this.handleSearch} class="detail_search">
                    <i class="iconfont icon_zxs detail_search_icon" />
                  </div>
                </el-tooltip>}
              </div>
              <div class="info">
                <div class="info_item">
                  <div class="info_title">基本属性</div>
                  <div class="info_wrap">
                    <div class="item">渠道名称：<text class="desc">{this.info.channelName}</text></div>
                    <div class="item">店铺名称：<text class="desc">{this.info.storeName}</text></div>
                    <div class="item">品牌：<text class="desc">{this.info.brandName}</text></div>
                    <div class="item">SPU：<text class="desc">{this.info.spu}</text></div>
                    <div class="item">
                      是否新品：
                      <text class="desc">{this.$filters.getEnumLabel(GOODS_IS_NEW_LIST, this.info.isNew)}</text>
                    </div>
                    <div class="item">
                      商品状态：
                      <text class="desc">
                        {this.$filters.getEnumLabel(GOODS_STATE_LIST, this.info.commodityStatus)}
                      </text>
                    </div>
                  </div>
                </div>
                <div class="info_item">
                  <div class="info_title">销售属性</div>
                  <div class="info_wrap">
                    <div class="item">价格：<text class="desc">{this.info.couponPrice}</text></div>
                    <div class="item">
                      上架日期：
                      <text class="desc">
                        {this.info.shelfDate && dayjs(this.info.shelfDate).format('YYYY年MM月DD日')}
                      </text>
                    </div>
                    <div class="item">
                      下架日期：
                      <text class="desc">{this.info.offDate && dayjs(this.info.offDate).format('YYYY年MM月DD日')}</text>
                    </div>
                    <div class="item">在架天数：<text class="desc">{this.info.daysOnShelf}天</text></div>
                  </div>
                </div>
                <div class="info_item">
                  <div class="info_title">设计属性</div>
                  <div class="info_wrap">
                    <div class="item">风格：<text class="desc">{this.info.style}</text></div>
                    <div class="item">裙型：<text class="desc">{this.info.skirtType}</text></div>
                    <div class="item">设计裁剪：<text class="desc">{this.info.designTailoring}</text></div>
                  </div>
                </div>
                  <div class="info_title">描述</div>
                  <div class="info_desc">
                    {this.info.description}
                  </div>
              </div>
            </div>
            {this.info.infoEntityList.length !== 0 && (
              <>
                <div class="common_title">
                  <div class="left">
                    <img src={IconGLSP} class="icon" />
                    <div>关联商品</div>
                  </div>
                </div>
                <div class="link_goods">
                  {this.info.infoEntityList.map(v => (
                    <div class="goods" onClick={() => this.handleBindGoodsItem(v)}>
                      <custom-image
                        src={v.ossPictureLink}
                        class="cover"
                        fit="cover"
                      />
                      <div class="name">{v.skcName}</div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        )}
      </el-dialog>
    );
  },
});
