import { ref, computed, nextTick, watch } from 'vue';
import { cloneDeep } from 'lodash-es';
import NP from 'number-precision';
import dayjs from 'dayjs';
import { useDictionaryStore } from '@/store/dictionary';
import { CX_CUSTOM_DICTIONARY_KEY } from '@/constant/dictionary';
import {
  getStyleGoods,
} from '@/modules/resource-manage/style/api';
import {
  GOODS_STATE_LIST,
  GOODS_IS_NEW_LIST,
  SORT_WORD_LIST,
  SORT_TYPE_ENUM,
  SORT_WORD_ENUM,
} from '@/modules/resource-manage/style/constant';
import { usePagination } from './use-pagination';
import {
  SEARCH_BASE_SETTING_ENUM,
  SEARCH_DESIGN_SETTING_ENUM,
  SEARCH_SALE_SETTING_ENUM,
} from '../constant';
import {
  IGoodsItem,
  ICustomParams,
  IParams,
  ISortParams,
  IFilterTagsItem,
  ISearchKeys,
} from '../type.d';
import { commonfuns } from 'cx-utils';
import { $filters } from '@/core/plugins/filter';
import { trackingExposure } from '@/utils/tracking';

export default () => {
  const {
    handleScrollToPage,
    initPagination,
    pageSize,
    reqPageIndex,
    currentViewPage,
  } = usePagination();
  const dictionaryStore = useDictionaryStore();
  const sortConfig = ref<ISortParams>({
    sortWord: SORT_WORD_ENUM.IN_SALE_DATE,
    sortType: SORT_TYPE_ENUM.DOWN,
  });
  const total = ref(0);
  const keyword = ref(''); // 搜索关键词
  const baseParams: ICustomParams = {
    categoryClass: [],
    style: [],
    skirtType: [],
    series: [],
    designTailoring: [],
    channelName: '',
    brandName: [],
    storeName: [],
    commodityStatus: '',
    isNew: '',
    couponPrice: ['', ''],
    discountRate: ['', ''],
    daysOnShelf: ['', ''],
    shelfDate: [],
    offDate: [],
    keyword: '',
  };
  const FLAT_DESIGN_CATEGORY = computed(() => dictionaryStore.flatCxDictionary(CX_CUSTOM_DICTIONARY_KEY.CATEGORY));
  const params = ref<ICustomParams>(cloneDeep(baseParams));
  const finish = ref(false);
  const goodsList = ref<IGoodsItem[]>([]);
  const formatParams = (row: ICustomParams) => {
    const data:IParams = {
      // ...row,
    };
    if (row?.shelfDate?.length === 2) {
      [
        data.shelfDateStart,
        data.shelfDateEnd,
      ] = [
        dayjs(row.shelfDate[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        dayjs(row.shelfDate[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      ];
      delete row.shelfDate;
    }
    if (row?.offDate?.length === 2) {
      [data.offDateStart, data.offDateEnd] = [
        dayjs(row.offDate[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        dayjs(row.offDate[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      ];
      delete row.offDate;
    }
    if (row.couponPrice?.length === 2) {
      [data.couponPriceStart, data.couponPriceEnd] = row.couponPrice;
      delete row.couponPrice;
    }
    if (row.discountRate?.length === 2) {
      [
        data.discountRateStart,
        data.discountRateEnd,
      ] = [
        row.discountRate[0]
          ? String(NP.divide(row.discountRate[0], 100)) : '',
        row.discountRate[1]
          ? String(NP.divide(row.discountRate[1], 100)) : '',
      ];
      delete row.discountRate;
    }
    if (row.daysOnShelf?.length === 2) {
      [data.daysOnShelfStart, data.daysOnShelfEnd] = row.daysOnShelf;
      delete row.daysOnShelf;
    }
    if (row.categoryClass?.length) {
      let ids: string[] = [];
      row.categoryClass.forEach((v) => {
        ids.push(...v);
      });
      ids = [...new Set([...ids])];
      const categoryClass1: string[] = [];
      const categoryClass2: string[] = [];
      const categoryClass3: string[] = [];
      ids.forEach((id) => {
        const current = FLAT_DESIGN_CATEGORY.value.find(v => v.label === id);
        if (current) {
          const set = new Set([...current.lastLevelChildIds, ...ids]);
          if (set.size === ids.length) {
            const index = current.pIds.length;
            if (index === 0) {
              categoryClass1.push(id);
            } else if (index === 1) {
              categoryClass2.push(id);
            } else if (index === 2) {
              categoryClass3.push(id);
            }
          }
        }
      });
      data.categoryClass1 = categoryClass1;
      data.categoryClass2 = categoryClass2;
      data.categoryClass3 = categoryClass3;
      delete row.categoryClass;
    }
    const currentParams = {
      ...row,
      ...data,
    };
    return currentParams;
  };
  const getList = async (current: number) => {
    try {
      const res = await getStyleGoods({
        ...formatParams(cloneDeep(params.value)),
        pageNum: current,
        pageSize: pageSize.value,
        ...sortConfig.value,
        channelType: '国外独立站',
      });
      const newList = res.data?.list || [];
      goodsList.value.push(...newList);
      trackingExposure(newList, 'skc');
      total.value = Number(res.data.total);
      if (Number(res.data.total) <= current * pageSize.value) {
        finish.value = true;
      } else {
        finish.value = false;
      }
      return Promise.resolve();
    } catch (e) {
      console.log('获取首页列表 error', e);
      return Promise.reject();
    }
  };
  const reload = () => {
    reqPageIndex.value = 1;
    goodsList.value = [];
    getList(reqPageIndex.value);
  };
  // 懒加载
  const listLoading = ref<boolean>(false);
  const listDisabled = computed(() => listLoading.value || finish.value);
  const loadMore = async () => {
    listLoading.value = true;
    reqPageIndex.value += 1;
    await getList(reqPageIndex.value);
    listLoading.value = false;
  };
  const handleChangeSort = (val: SORT_WORD_ENUM) => {
    if (val === sortConfig.value.sortWord) {
      if (val === SORT_WORD_ENUM.DEFAULT) {
        return;
      }
      sortConfig.value.sortType = Object.values(SORT_TYPE_ENUM).find(v => v !== sortConfig.value.sortType) || '';
    } else {
      sortConfig.value.sortWord = val;
      sortConfig.value.sortType = SORT_TYPE_ENUM.DOWN;
    }
    reload();
  };
  const handleCurrentSizeChange = async (val: number) => {
    try {
      if (listLoading.value) return;
      if (val > reqPageIndex.value) {
        listLoading.value = true;
        const reqArr = [];
        while (reqPageIndex.value < val) {
          reqPageIndex.value += 1;
          // eslint-disable-next-line no-await-in-loop
          reqArr.push(getStyleGoods({
            ...formatParams(cloneDeep(params.value)),
            pageNum: reqPageIndex.value,
            pageSize: pageSize.value,
            ...sortConfig.value,
            channelType: '国外独立站',
          }));
        }
        const resArr = await Promise.all(reqArr);
        const list:IGoodsItem[] = [];
        resArr.forEach((v, i) => {
          const { data } = v;
          list.push(...data.list);
          if (i === resArr.length - 1) {
            total.value = Number(data.total);
            if (Number(data.total) <= reqPageIndex.value * pageSize.value) {
              finish.value = true;
            } else {
              finish.value = false;
            }
          }
        });
        goodsList.value.push(...list);
        trackingExposure(list, 'skc');
        await nextTick();
        handleScrollToPage(val);
      } else {
        handleScrollToPage(val);
      }
    } catch (error) {
      console.log('获取列表数据错误', error);
    }
    listLoading.value = false;
  };
  const isEmpty = computed(() => {
    return !goodsList.value.length && !listLoading.value;
  });
  const tagSortList = ref<Set<ISearchKeys>>(new Set([]));
  const filtersTags = computed(() => {
    const tags: IFilterTagsItem[] = [];
    Object.entries(params.value).forEach((v) => {
      const [key] = v;
      const value = v[1] as any;
      switch (key) {
        case SEARCH_BASE_SETTING_ENUM.STORE_NAME:
          if (value.length === 0) {
            tagSortList.value.delete(key);
            return;
          }
          tags.push({
            label: '店铺',
            key,
            content: value.join('、'),
          });
          tagSortList.value.add(key);
          break;
        case SEARCH_BASE_SETTING_ENUM.BRAND_NAME:
          if (value.length === 0) {
            tagSortList.value.delete(key);
            return;
          }
          tags.push({
            label: '品牌',
            key,
            content: value.join('、'),
          });
          tagSortList.value.add(key);
          break;
        case SEARCH_DESIGN_SETTING_ENUM.CATEGORY:
          if (value.length === 0) {
            tagSortList.value.delete(key);
            return;
          }
          tags.push({
            label: '品类',
            key,
            content: value.map((cate: string[]) => cate.join('｜')).join('、'),
          });
          tagSortList.value.add(key);
          break;
        case SEARCH_DESIGN_SETTING_ENUM.STYLE:
          if (value.length === 0) {
            tagSortList.value.delete(key);
            return;
          }
          tags.push({
            label: '风格',
            key,
            content: value.join('、'),
          });
          tagSortList.value.add(key);
          break;
        case SEARCH_DESIGN_SETTING_ENUM.SKIRT_TYPE:
          if (value.length === 0) {
            tagSortList.value.delete(key);
            return;
          }
          tags.push({
            label: '裙型',
            key,
            content: value.join('、'),
          });
          tagSortList.value.add(key);
          break;
        case SEARCH_DESIGN_SETTING_ENUM.DESIGN_CUTTING:
          if (value.length === 0) {
            tagSortList.value.delete(key);
            return;
          }
          tags.push({
            label: '设计裁剪',
            key,
            content: value.join('、'),
          });
          tagSortList.value.add(key);
          break;
        case SEARCH_SALE_SETTING_ENUM.SHELF_DATE:
          if (value.length === 2) {
            const [
              sDate,
              eDate,
            ] = [
              dayjs(value[0]).format('YYYY.MM.DD'),
              dayjs(value[1]).format('YYYY.MM.DD'),
            ];
            tags.push({
              label: '上架日期',
              key,
              content: `${sDate}-${eDate}`,
            });
            tagSortList.value.add(key);
          } else {
            tagSortList.value.delete(key);
          }
          break;
        case SEARCH_SALE_SETTING_ENUM.OFF_DATE:
          if (value.length === 2) {
            const [
              sDate,
              eDate,
            ] = [
              dayjs(value[0]).format('YYYY.MM.DD'),
              dayjs(value[1]).format('YYYY.MM.DD'),
            ];
            tags.push({
              label: '下架日期',
              key,
              content: `${sDate}-${eDate}`,
            });
            tagSortList.value.add(key);
          } else {
            tagSortList.value.delete(key);
          }
          break;
        case SEARCH_SALE_SETTING_ENUM.DAYS_ON_SHELF:
          if (value.length === 2) {
            const [
              sDay,
              eDay,
            ] = value;
            if (commonfuns.isEmpty(sDay) || commonfuns.isEmpty(eDay)) {
              tagSortList.value.delete(key);
              return;
            }
            tags.push({
              label: '在架天数',
              key,
              content: `${sDay}-${eDay}`,
            });
            tagSortList.value.add(key);
          } else {
            tagSortList.value.delete(key);
          }
          break;
        case SEARCH_BASE_SETTING_ENUM.IS_NEW:
          if (commonfuns.isEmpty(value)) {
            tagSortList.value.delete(key);
            return;
          }
          tags.push({
            label: '是否新品',
            key,
            content: $filters.getEnumLabel(GOODS_IS_NEW_LIST, value as string),
          });
          tagSortList.value.add(key);
          break;
        case SEARCH_BASE_SETTING_ENUM.COMMODITY_STATUS:
          if (commonfuns.isEmpty(value)) {
            tagSortList.value.delete(key);
            return;
          }
          tags.push({
            label: '商品状态',
            key,
            content: $filters.getEnumLabel(GOODS_STATE_LIST, value as string),
          });
          tagSortList.value.add(key);
          break;
        case SEARCH_SALE_SETTING_ENUM.COUPON_PRICE:
          if (value.length === 2) {
            const [
              sPrice,
              ePrice,
            ] = value;
            if (commonfuns.isEmpty(sPrice) || commonfuns.isEmpty(ePrice)) {
              tagSortList.value.delete(key);
              return;
            }
            tags.push({
              label: '价格',
              key,
              content: `${sPrice}-${ePrice}`,
            });
            tagSortList.value.add(key);
          } else {
            tagSortList.value.delete(key);
          }
          break;
        default:
          break;
      }
    });
    return tags;
  });
  const sortFiltersTags = computed(() => {
    return [...tagSortList.value].map((v) => {
      return filtersTags.value.find(it => it.key === v);
    });
  });
  const handleRemoveFilterItem = (
    key: SEARCH_BASE_SETTING_ENUM|SEARCH_DESIGN_SETTING_ENUM|SEARCH_SALE_SETTING_ENUM,
  ) => {
    switch (key) {
      case SEARCH_BASE_SETTING_ENUM.STORE_NAME:
      case SEARCH_BASE_SETTING_ENUM.BRAND_NAME:
      case SEARCH_DESIGN_SETTING_ENUM.CATEGORY:
      case SEARCH_DESIGN_SETTING_ENUM.STYLE:
      case SEARCH_DESIGN_SETTING_ENUM.SKIRT_TYPE:
      case SEARCH_DESIGN_SETTING_ENUM.DESIGN_CUTTING:
      case SEARCH_SALE_SETTING_ENUM.SHELF_DATE:
      case SEARCH_SALE_SETTING_ENUM.OFF_DATE:
        params.value[key] = [];
        break;
      case SEARCH_SALE_SETTING_ENUM.DAYS_ON_SHELF:
      case SEARCH_SALE_SETTING_ENUM.COUPON_PRICE:
        params.value[key] = ['', ''];
        break;
      case SEARCH_BASE_SETTING_ENUM.IS_NEW:
      case SEARCH_BASE_SETTING_ENUM.COMMODITY_STATUS:
        params.value[key] = '';
        break;
      default:
        break;
    }
  };
  const handleReset = () => {
    tagSortList.value.clear();
    params.value = cloneDeep(baseParams);
    params.value.keyword = keyword.value;
  };
  const handleConfirmKeyword = () => {
    params.value.keyword = keyword.value;
    reload();
  };
  watch(() => filtersTags.value, () => {
    reload();
  });
  return {
    handleConfirmKeyword,
    sortFiltersTags,
    handleCurrentSizeChange,
    total,
    initPagination,
    pageSize,
    currentViewPage,
    handleChangeSort,
    sortConfig,
    sortList: [...SORT_WORD_LIST],
    params,
    goodsList,
    listLoading,
    finish,
    listDisabled,
    isEmpty,
    loadMore,
    reload,
    keyword,
    handleRemoveFilterItem,
    handleReset,
  };
};
